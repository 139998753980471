<template>
  <div id="extraActivities">
    <section
      class="page-header"
      style="background-image: url('/imgs/extra-activities-01.png')"
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container">
        <p>
          {{ $lang("desc") }}
        </p>
      </div>
    </section>

    <section class="py-6">
      <div class="container text-center">
        <h4 class="text-primary mb-5">{{ $lang("monthly.title") }}</h4>

        <p class="text-medium mb-6" v-html="$lang('monthly.subtitle')" />

        <swiper
          class="swiper-primary"
          :modules="modules"
          :pagination="{ clickable: false }"
          :breakpoints="swiperBreakpoints"
          loop
          autoplay
          centeredSlides
          style="--pagination-bottom: -35px"
        >
          <swiper-slide v-for="n of Array.from(Array(12).keys())" :key="n">
            <div
              class="img-container minh-4 contain"
              :style="`background-image: url('/imgs/extra-activities/${
                n + 1 < 10 ? '0' + (n + 1) : n + 1
              }.png')`"
            />
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="py-6 bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div
              class="img-container img-container-circle w-100 maxw-4 mx-auto mb-5 mb-md-0"
              style="background-image: url('/imgs/extra-activities-04.png')"
            />
          </div>
          <div
            class="col-lg-8 col-md-6 d-flex align-items-center text-center text-left-md"
          >
            <div>
              <h4 class="text-primary mb-5">
                {{ $lang("quarterly.title") }}
              </h4>
              <p class="text-medium" v-html="$lang('quarterly.subtitle')" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <h4 class="text-primary mb-5">{{ $lang("extra.title") }}</h4>

        <div
          class="extra-class-item"
          v-for="(i, index) of $lang('extra.items')"
          :key="index"
        >
          <div class="item-head">
            <div
              class="img-container img-container-circle"
              :style="`background-image: url('${i.img}')`"
            />
          </div>
          <div class="item-body">
            <div>
              <h4>{{ i.title }}</h4>
              <p class="text-medium lh-4">
                {{ i.desc }}
              </p>
            </div>
          </div>
        </div>

        <button
          class="btn btn-primary px-5 mx-auto mt-6"
          @click="$router.push('/contact-us')"
        >
          {{ $lang("extra.button") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination, Navigation, Autoplay],
    swiperBreakpoints: {
      0: { slidesPerView: 1, spaceBetween: 20 },
      991: { slidesPerView: 3, spaceBetween: 20 },
    },
  }),
};
</script>
